/* eslint-disable no-console */
import axios from 'axios';

export const checkUser = async (
  keycloakId,
  email,
  lastName,
  firstName,
  gemiNumber
) => {
  let userInfos;
  try {
    const token = sessionStorage.getItem('authentication');
    const headers = {
      Authorization: 'bearer ' + token,
    };
    userInfos = await axios.post(
      process.env.NEXT_PUBLIC_API_URL.concat('/checkUser/'),
      {},
      {
        params: { keycloakId, email, lastName, firstName, gemiNumber },
        headers,
      }
    );
    return await userInfos.data;
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
};

export async function getProducts(lang) {
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/product/all/',
      params: { lang: lang },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProductCardImage(fileId) {
  try {
    return await axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/product/image/',
      params: { fileId: fileId },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProductById(id, lang) {
  try {
    return await axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/product/',
      params: { id: id, lang: lang },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProductsProducers(productId, lang) {
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/product/producers/',
      params: {
        productId: productId,
        lang: lang,
      },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function addProducer(productId, companyId, language, profession) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'POST',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/producer/',
      params: {},
      data: {
        productId: productId,
        companyId: companyId,
        language: language,
        profession: profession,
      },
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function editProducer(id, profession) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'PUT',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/producer/',
      params: {},
      data: {
        id: id,
        profession: profession,
      },
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProductAlbumPhotos(productId) {
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/product/album/',
      params: {
        productId: productId,
      },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProducerById(companyId, language) {
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/producer/',
      params: { companyId: companyId, language: language },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProducerLogo(companyId) {
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/producer/logo/',
      params: { companyId: companyId },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProducerPhotos(companyId) {
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/producer/photos/',
      params: { companyId: companyId },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProducerFiles(companyId, language) {
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/producer/files/',
      params: { companyId: companyId, language: language },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function downloadCompanyFile(fileName, fileId) {
  const params = {};
  if (fileId && fileId !== '') params.fileId = fileId;

  const headers = {
    Authorization: 'bearer ',
  };
  const responseType = 'blob';
  try {
    const request = await axios.get(
      process.env.NEXT_PUBLIC_API_URL.concat('/download/' + fileName),
      {
        params,
        headers,
        responseType,
      }
    );

    if (request.data) {
      const type = request.headers['content-type'];
      const blob = new Blob([request.data], { type: type });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getAllCompanies() {
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ',
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/companies/',
      params: {},
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function deleteProducer(productId, companyId) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'DELETE',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/producer/',
      params: {},
      data: { productId: productId, companyId: companyId },
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function addProduct(params) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'POST',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/product/',
      params: {},
      data: params,
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProductPhotos(productId, language) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/photos/',
      params: { productId: productId, language: language },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function updateCentralPhoto(isCentral, id, productId) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'PUT',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/central/',
      params: { isCentral: isCentral, id: id, productId: productId },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function updateProduct(
  language,
  productId,
  title,
  description,
  companiesDescription
) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'PUT',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/product/',
      params: {},
      data: {
        language: language,
        productId: productId,
        title: title,
        description: description,
        companiesDescription: companiesDescription,
      },
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function addProductImages(params, formData) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/photos/',
      params: params,
      data: formData,
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function deletePhoto(photoId) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'PUT',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/photos/',
      params: {},
      data: {
        photoId: photoId,
      },
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function deleteProduct(productId, language) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'DELETE',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/product/',
      params: {
        productId: productId,
        language: language,
      },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function getProductFiles(productId, language) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/files/',
      params: {
        productId: productId,
        language: language,
      },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function downloadProductFile(fileName, fileId) {
  const params = {};
  if (fileId && fileId !== '') params.fileId = fileId;

  const headers = {
    Authorization: 'bearer ',
  };
  const responseType = 'blob';
  try {
    const request = await axios.get(
      process.env.NEXT_PUBLIC_API_URL.concat('/file/' + fileName),
      {
        params,
        headers,
        responseType,
      }
    );

    if (request.data) {
      const type = request.headers['content-type'];
      const blob = new Blob([request.data], { type: type });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function addProductFiles(params, formData) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/files/',
      params: params,
      data: formData,
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}

export async function deleteProductFiles(id) {
  const token = sessionStorage.getItem('authentication');
  try {
    return await axios({
      headers: {
        Authorization: 'bearer ' + token,
      },
      method: 'PUT',
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      url: '/files/',
      params: {
        id: id,
      },
      data: {},
    }).then((res) => res.data);
  } catch (error) {
    console.error(error);
    return error.response.status;
  }
}
