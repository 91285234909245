import Image from 'next/image';
import Link from 'next/link';
import { Container, Divider, Typography, Box } from '@mui/material/';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/Footer.module.css';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Container maxWidth='100%' className={styles.container}>
      <Divider />

      <Box
        width='100%'
        className={styles.parentBox}
        justifyContent='space-between'
      >
        <Box display='flex'>
          <Box>
            <Image src='/pdo.png' alt='pdo' width={141} height={141} />
          </Box>
          <Box>
            <Image src='/pgi.png' alt='pgi' width={141} height={141} />
          </Box>
        </Box>

        <Box className={styles.centralContent}>
          <Typography
            className={styles.chamberInfo}
            color='textSecondary'
            align='center'
          >
            {t('chambersName') +
              ' ' +
              t('chamberPhone') +
              ' ' +
              t('chamberAdress')}
          </Typography>
          <Box display='flex' justifyContent='center'>
            <Image
              src={`/static/${process.env.CHAMBER_NAME}/espa.jpg`}
              alt='espa2014-2020 EU'
              width={490}
              height={120}
              className={styles.espa}
            />
          </Box>
          <Typography
            variant='body2'
            color='textSecondary'
            align='center'
            style={{ fontSize: 9 }}
          >
            Designed and Developed by Knowledge ΑΕ
          </Typography>
        </Box>

        <Box className={styles.links} display='flex'>
          <Link href='/admin'>
            <a className={styles.terms}>{t('adminButton')}</a>
          </Link>
          <div style={{ paddingLeft: 5, paddingRight: 5 }}>|</div>
          <Link href='/terms'>
            <a className={styles.terms}>{t('terms')}</a>
          </Link>
          <div style={{ paddingLeft: 5, paddingRight: 5 }}>|</div>
          <Link href='/privacy'>
            <a className={styles.terms}>{t('privacy')}</a>
          </Link>
        </Box>
      </Box>
    </Container>
  );
}
