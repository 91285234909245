import '../styles/globals.css';
import React, { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/node';
import Head from 'next/head';
import TopBar from '../components/topBar';
import styles from '../styles/Home.module.css';
import UserContext from '../src/context/UserContext';
import { AuthProvider, ProtectRoute } from '../src/AuthKeycloakProvider';
import Footer from '../components/footer';
import theme from '../src/theme';
import { appWithTranslation } from '../utils/i18n';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'development',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

function MyApp({ Component, pageProps, err }) {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const [keycloakstate, setKeycloakstate] = useState({
    keycloak: null,
    authenticated: false,
    token: null,
    userName: null,
    user_id: null,
    roles: null,
  });

  const value = useMemo(() => ({ keycloakstate, setKeycloakstate }), [
    keycloakstate,
    setKeycloakstate,
  ]);

  const router = useRouter();

  return (
    <UserContext.Provider value={value}>
      <AuthProvider>
        <ProtectRoute>
          <ThemeProvider theme={theme}>
            <Head>
              <title>{process.env.NEXT_PUBLIC_CHAMBER_APP_TITLE}</title>
              <link
                rel='icon'
                href={`/static/${process.env.CHAMBER_NAME}/favicon.png`}
              />
            </Head>
            <StyledEngineProvider injectFirst>
              {router.pathname !== '/' ? (
                <div className={styles.topBar}>
                  <TopBar />
                </div>
              ) : null}

              <main>
                <CssBaseline />
                <Component {...pageProps} err={err} />
              </main>
              <>
                <Footer />
              </>
            </StyledEngineProvider>
          </ThemeProvider>
        </ProtectRoute>
      </AuthProvider>
    </UserContext.Provider>
  );
}

MyApp.getInitialProps = async () => ({
  pageProps: { namespacesRequired: ['common'] },
});

export default appWithTranslation(MyApp);
