export function getUserType(keycloakState) {
  if (
    keycloakState?.authenticated === true &&
    JSON.parse(sessionStorage.getItem('userSession'))?.isAdmin
  ) {
    return 'admin';
  } else if (
    keycloakState?.authenticated === true &&
    JSON.parse(sessionStorage.getItem('userSession'))?.isChamberUser
  ) {
    return 'chamber';
  } else if (
    keycloakState?.authenticated === true &&
    JSON.parse(sessionStorage.getItem('userSession'))?.companyId !== null
  ) {
    return 'company';
  } else if (keycloakState?.authenticated === true) {
    return 'guest';
  }
}
