/* eslint-disable prefer-const */
/* eslint-disable no-console */
import { createContext, useContext, useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useRouter } from 'next/router';
import { useIdleTimer } from 'react-idle-timer';
import UserContext from './context/UserContext';
import { checkUser } from './dataProvider';

const AuthContext = createContext({});
const Keycloak = typeof window !== 'undefined' ? require('keycloak-js') : null;

export const AuthProvider = ({ children }) => {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [idle, setIdle] = useState(false);
  const [failedAuth, setFailedAuth] = useState(false);
  let { keycloakstate, setKeycloakstate } = useContext(UserContext);

  // set 'idle' after 30 minutes of inactivity
  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: () => setIdle(true),
    onActive: () => setIdle(false),
    debounce: 500,
  });

  const logout = () => {
    sessionStorage.removeItem('authentication');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('userSession');

    let currentURL = window.location.protocol + '//' + window.location.host;
    keycloakstate.keycloak.logout({ redirectUri: currentURL });
  };

  const pagesRequiresAuth = async () => {
    let authCheck = false;
    const securePages = [
      '/admin',
      '/admin/add',
      '/admin/producers/[id]',
      '/admin/edit/[id]',
      '/admin/files/[id]',
    ];
    if (securePages.includes(router.pathname)) {
      authCheck = true;
    }
    return authCheck;
  };

  const getAuthentication = async () => {
    let keycloakId;
    let email;
    let lastName;
    let firstName;
    let gemiNumber;
    let isAuthenticated = false;

    const keycloak = Keycloak('../../keycloak/' + process.env.keycloak);
    const authFlag = await pagesRequiresAuth();

    if (authFlag === true) {
      await keycloak
        .init({ onLoad: 'login-required' })
        .then((authenticated) => {
          setKeycloakstate({
            keycloak: keycloak,
            authenticated: authenticated,
            token: keycloak.token,
            userName: keycloak.tokenParsed.name,
            user_id: keycloak.tokenParsed.sub,
            roles: keycloak.tokenParsed?.realm_access?.roles,
          });

          if (authenticated) {
            keycloakId = keycloak.tokenParsed.sub;
            email = keycloak.tokenParsed.email;
            lastName = keycloak.tokenParsed.family_name;
            firstName = keycloak.tokenParsed.given_name;
            gemiNumber = keycloak.tokenParsed.gemi_number;

            isAuthenticated = true;
          } else {
            let currentURL =
              window.location.protocol +
              '//' +
              window.location.host +
              '/unauthorizedAccess';
            keycloakstate.keycloak.logout({ redirectUri: currentURL });
          }
        })
        .catch(() => {
          router.push('/');
        });
    } else if (!failedAuth) {
      await keycloak
        .init({ onLoad: 'check-sso', checkLoginIframe: true })
        .then((authenticated) => {
          setKeycloakstate({
            keycloak: keycloak,
            authenticated: authenticated,
            token: keycloak.token,
            userName: keycloak.tokenParsed?.name,
            user_id: keycloak.tokenParsed?.sub,
            roles: keycloak.tokenParsed?.realm_access?.roles,
          });
          if (authenticated) {
            keycloakId = keycloak.tokenParsed.sub;
            email = keycloak.tokenParsed.email;
            lastName = keycloak.tokenParsed.family_name;
            firstName = keycloak.tokenParsed.given_name;
            gemiNumber = keycloak.tokenParsed.gemi_number;

            isAuthenticated = true;
          } else {
            setFailedAuth(true);
          }
        })
        .catch(() => {
          router.push('/');
        });
    }

    return {
      keycloakId,
      email,
      lastName,
      firstName,
      gemiNumber,
      authenticated: isAuthenticated,
    };
  };

  const createUserSession = async (userInfos) => {
    const sessionUserInfo = {
      id: userInfos.id,
      lastName: userInfos.lastName,
      firstName: userInfos.firstName,
      isChamberUser: userInfos.isChamberUser,
      isAdmin: userInfos.isAdmin,
      companyId: userInfos.companyId,
      keycloakId: userInfos.keycloakId,
    };
    await sessionStorage.setItem(
      'userSession',
      JSON.stringify(sessionUserInfo)
    );

    return true;
  };

  // help function used in effect below
  const updateToken = async () => {
    // dont run any logic if logged out
    if (keycloakstate.authenticated) {
      // if user is not idle, update token
      if (!idle) {
        try {
          // update the token if it has less than 5 mins life
          await keycloakstate.keycloak.updateToken(5 * 60);
          // update the state
          setKeycloakstate({
            ...keycloakstate,
            token: keycloakstate.keycloak.token,
            userName: keycloakstate.keycloak.tokenParsed.name,
            user_id: keycloakstate.keycloak.tokenParsed.sub,
            roles: keycloakstate.keycloak.tokenParsed.realm_access.roles,
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        await logout();
      }
    }
  };

  const keycloakFunc = async () => {
    setLoading(true);

    if (window !== undefined && keycloakstate.authenticated !== true) {
      let {
        keycloakId,
        email,
        lastName,
        firstName,
        gemiNumber,
        authenticated,
      } = await getAuthentication();

      if (authenticated) {
        const userInfos = await checkUser(
          keycloakId,
          email,
          lastName,
          firstName,
          gemiNumber
        );
        await createUserSession(userInfos);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    keycloakFunc();
  }, [router.pathname]);

  // try to update keycloak token every minute
  useEffect(() => {
    // if keycloak is loaded, try to update token every minute
    if (keycloakstate?.keycloak?.updateToken && setKeycloakstate) {
      const interval = setInterval(() => updateToken(), 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [keycloakstate, setKeycloakstate, idle]);

  // update keycloak session when keycloak data changes
  useEffect(() => {
    if (keycloakstate?.keycloak && keycloakstate?.token) {
      window.accessToken = keycloakstate.token;
      sessionStorage.setItem('authentication', keycloakstate.token);
      sessionStorage.setItem(
        'refreshToken',
        keycloakstate.keycloak.refreshToken
      );
    }
  }, [keycloakstate]);

  // single-log-out handler
  useEffect(() => {
    if (keycloakstate?.keycloak) {
      // check-sso by default does not Single-Sign-Out, so we do it here
      keycloakstate.keycloak.onAuthLogout = logout;
    }
  }, [keycloakstate?.keycloak]);

  return (
    <AuthContext.Provider value={{ loading }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
  const { loading } = useAuth();
  if (loading) {
    return <LinearProgress />;
  }
  return children;
};
