const NextI18Next = require('next-i18next').default;
const path = require('path');

module.exports = new NextI18Next({
  defaultLanguage: process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE,
  otherLanguages: ['en'],
  localePath:
    typeof window === 'undefined'
      ? path.resolve('./public/locales')
      : '../locales',
  detection: {
    lookupCookie: 'next-i18next',
    order: ['cookie', 'querystring', 'localStorage', 'path', 'subdomain'],
    caches: ['cookie'],
  },
});
