import React, { useContext, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PublicIcon from '@mui/icons-material/Public';
import styles from '../../styles/TopBar.module.css';
import UserContext from '../../src/context/UserContext';
import { getUserType } from '../../src/authentication';

export default function TopBar() {
  const router = useRouter();
  const [t, i18n] = useTranslation();
  const { keycloakstate } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickLanguages = (e) => {
    setLangAnchorEl(e.currentTarget);
  };

  const handleCloseLanguages = () => {
    setLangAnchorEl(null);
  };

  const handleChooseGreek = () => {
    i18n.changeLanguage('el');
    setLangAnchorEl(null);
  };

  const handleChooseEnglish = () => {
    i18n.changeLanguage('en');
    setLangAnchorEl(null);
  };

  const logout = async () => {
    sessionStorage.removeItem('authentication');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('userSession');

    var currentURL = window.location.protocol + '//' + window.location.host;
    const keycloakJson = (
      await import(`../../public/keycloak/${process.env.keycloak}`)
    ).default;
    window.location =
      keycloakJson['auth-server-url'] +
      '/realms/' +
      keycloakJson['realm'] +
      '/protocol/openid-connect/logout?redirect_uri=' +
      currentURL;
  };

  const goHome = () => {
    router.push('/');
  };

  const redirectToAdmin = () => {
    router.push('/admin');
    handleMenuClose();
  };

  const menuId = 'logged-user';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {getUserType(keycloakstate) === 'admin' ? (
        <MenuItem onClick={redirectToAdmin}>
          {t('adminMenuProductManagement')}
        </MenuItem>
      ) : null}

      <MenuItem onClick={logout}>{t('adminMenuLogout')}</MenuItem>
    </Menu>
  );

  const renderLanguageMenu = (
    <Menu
      anchorEl={langAnchorEl}
      keepMounted
      open={Boolean(langAnchorEl)}
      onClose={handleCloseLanguages}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={handleChooseGreek}>
        <Box width='100%' display='flex' justifyContent='space-between'>
          EL
        </Box>
      </MenuItem>
      <MenuItem onClick={handleChooseEnglish}>
        <Box width='100%' display='flex' justifyContent='space-between'>
          EN
        </Box>
      </MenuItem>
    </Menu>
  );
  return (
    <>
      <div className={styles.grow}>
        <AppBar position='static' className={styles.appBar} elevation={0}>
          <Toolbar>
            <Box width='100%' display='flex' justifyContent='space-between'>
              <Box>
                <Image
                  src={`/static/${process.env.CHAMBER_NAME}/logo.png`}
                  alt='chamber-logo'
                  width={100}
                  height={75}
                  onClick={goHome}
                  className={styles.logo}
                />
              </Box>

              <Box p={2}>
                <Typography
                  className={styles.titleSecondBar}
                  textAlign='center'
                  fontWeight='bold'
                  style={{ color: '#212529' }}
                >
                  {t('title')}
                </Typography>
              </Box>

              <Box p={2} display='flex'>
                <MenuItem className={styles.menuItem} onClick={goHome}>
                  {t('homeMenu')}
                </MenuItem>
                <MenuItem
                  className={styles.menuButton}
                  onClick={handleClickLanguages}
                >
                  <Tooltip title={t('changeLanguage')}>
                    <PublicIcon />
                  </Tooltip>
                  {i18n.language === 'el' ? (
                    <Box mt={0.2}>
                      <Typography>EL</Typography>
                    </Box>
                  ) : (
                    <Box mt={0.2}>
                      <Typography>EN</Typography>
                    </Box>
                  )}
                </MenuItem>

                {keycloakstate?.authenticated === true ? (
                  <MenuItem
                    onClick={handleProfileMenuOpen}
                    className={styles.menuButton}
                  >
                    <IconButton
                      edge='end'
                      aria-label='account of current user'
                      aria-controls={menuId}
                      aria-haspopup='true'
                      className={styles.iconButton}
                    >
                      <AccountCircle />
                    </IconButton>
                    {keycloakstate?.userName}
                  </MenuItem>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderLanguageMenu}
      </div>
    </>
  );
}
